input:focus,
textarea:focus,
select:focus,
a:focus {
  outline: none;
}
.react-datepicker__navigation-icon {
  position: unset !important;
}
.react-datepicker__navigation-icon--next:before {
  left: -4px !important;
}

.react-datepicker__navigation-icon--previous:before {
  right: -4px !important;
}
