.carousel.carousel-slider .control-arrow {
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin: 310px 150px;
    padding: 0px;
    background-color: #000;
    width: 52px;
    height: 52px;
    border: 1px solid black;
    border-radius: 50px;
    opacity: 0.8;

}

.carousel .control-prev.control-arrow:before {
    border-right: 12px solid #fff;
    margin: 2px;
    margin-right: 6px;
}

.carousel .control-next.control-arrow:before{
    border-left: 12px solid #fff;
    margin: 2px;
    margin-left: 6px;
}

.carousel .control-dots{
    margin : 60px 0px;
}

.carousel .slide img {
    width: 1785px;
    vertical-align: top;
    border: 0;
}

p.legend {
    display: none;
}

/* responsive start  */

@media (max-width: 1440px) {
    .carousel.carousel-slider .control-arrow {
        top: 0;
        color: #fff;
        font-size: 26px;
        bottom: 0;
        margin: 234px 100px;
        padding: 0px;
        background-color: #000;
        width: 52px;
        height: 52px;
        border: 1px solid black;
        border-radius: 50px;
        opacity: 0.8;
    }
}

@media (max-width: 1024px) {
    .carousel.carousel-slider .control-arrow {
        top: 0;
        color: #fff;
        font-size: 26px;
        bottom: 0;
        margin: 157px 50px;
        padding: 0px;
        background-color: #000;
        width: 52px;
        height: 52px;
        border: 1px solid black;
        border-radius: 50px;
        opacity: 0.8;
    }
}

@media (max-width: 768px){
    .carousel.carousel-slider .control-arrow {
        top: 0;
        color: #fff;
        font-size: 26px;
        bottom: 0;
        margin: 109px 50px;
        padding: 0px;
        background-color: #000;
        width: 40px;
        height: 40px;
        border: 1px solid black;
        border-radius: 50px;
        opacity: 0.8;
    }
}

@media (max-width: 425px){
    .carousel.carousel-slider .control-arrow {
        top: 0;
        color: #fff;
        font-size: 26px;
        bottom: 0;
        margin: 94px 50px;
        padding: 0px;
        background-color: #000;
        width: 35px;
        height: 35px;
        border: 1px solid black;
        border-radius: 50px;
        opacity: 0.8;
    }
    .carousel .control-prev.control-arrow:before {
        border-right: 12px solid #fff;
        margin: 5px;
        margin-right: 6px;
    }
    
    .carousel .control-next.control-arrow:before{
        border-left: 12px solid #fff;
        margin: 5px;
        margin-left: 6px;
    }

    .carousel .control-dots {
        margin: 10px 0px;
    }
}
